var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.loading)?_c('article',{staticClass:"user-edit box"},[_c('VCard',{staticClass:"user-edit__body"},[_c('h1',{staticClass:"page-title"},[_vm._v(" "+_vm._s(_vm.documentTitle)+" ")]),_c('div',{staticClass:"user-edit__content"},[_c('VForm',{ref:"form",staticClass:"fill-height",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('VCard',{attrs:{"flat":""}},[_c('VCardText',[_c('VRow',[(_vm.preparedFields.gender?.isEmpty)?_c('VCol',{attrs:{"cols":"12"}},[_c('VRadioGroup',{staticClass:"required",attrs:{"label":_vm.$t('gender.one'),"row":"","rules":[(v) => !!v || 'Заполните поле']},model:{value:(_vm.preparedFields.gender.value),callback:function ($$v) {_vm.$set(_vm.preparedFields.gender, "value", $$v)},expression:"preparedFields.gender.value"}},[_c('VRadio',{attrs:{"label":_vm.$t('gender_female.one'),"value":"f"}}),_c('VRadio',{attrs:{"label":_vm.$t('gender_male.one'),"value":"m"}})],1)],1):_vm._e(),(_vm.preparedFields.dob?.isEmpty)?_c('VCol',{attrs:{"cols":"12","md":_vm.preparedFields.citizenship?.isEmpty ? '3' : '12'}},[_c('VTextField',{staticClass:"required",attrs:{"placeholder":"ДД.ММ.ГГГГ","outlined":"","type":"date","max":_vm.MAX_DATE,"min":_vm.MIN_DATE,"label":_vm.$t('dob.one'),"rules":[
                    (v) => (v || '').trim().length > 0 || 'Заполните поле',
                    (v) =>
                      (v || '').trim().length <= 63 ||
                      'В дате рождения не должно быть больше 63 символов',
                  ]},model:{value:(_vm.preparedFields.dob.value),callback:function ($$v) {_vm.$set(_vm.preparedFields.dob, "value", $$v)},expression:"preparedFields.dob.value"}})],1):_vm._e(),(_vm.preparedFields.citizenship?.isEmpty)?_c('VCol',{attrs:{"cols":"12","md":_vm.preparedFields.dob?.isEmpty ? '9' : '12'}},[_c('VAutocomplete',{staticClass:"required",attrs:{"outlined":"","items":_vm.citizenshipItems,"item-text":"title","item-value":"code","label":_vm.$t('citizenship.one'),"rules":[
                    (v) => (v || '').trim().length > 0 || 'Заполните поле',
                  ]},model:{value:(_vm.preparedFields.citizenship.value),callback:function ($$v) {_vm.$set(_vm.preparedFields.citizenship, "value", $$v)},expression:"preparedFields.citizenship.value"}})],1):_vm._e(),(_vm.preparedFields.inn?.isEmpty)?_c('VCol',{attrs:{"cols":"12","md":"6"}},[_c('VTextField',{staticClass:"required",attrs:{"outlined":"","label":_vm.$t('inn.one'),"placeholder":"AA BB CCCCC D или AA BB CCCCCC DD","rules":[
                    (v) => (v || '').trim().length > 0 || 'Заполните поле',
                    (v) => _vm.validateINN(v)
                  ]},on:{"input":_vm.updateINN},model:{value:(_vm.preparedFields.inn.value),callback:function ($$v) {_vm.$set(_vm.preparedFields.inn, "value", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"preparedFields.inn.value"}})],1):_vm._e(),(_vm.preparedFields.snils?.isEmpty)?_c('VCol',{attrs:{"cols":"12","md":"6"}},[_c('VTextField',{directives:[{name:"mask",rawName:"v-mask",value:('###-###-### ##'),expression:"'###-###-### ##'"}],ref:"snils",staticClass:"required",attrs:{"outlined":"","label":_vm.$t('snils.one'),"placeholder":"XXX-XXX-XXX YY","rules":[(v) => _vm.validateSNILS(v)]},model:{value:(_vm.preparedFields.snils.value),callback:function ($$v) {_vm.$set(_vm.preparedFields.snils, "value", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"preparedFields.snils.value"}})],1):_vm._e(),(_vm.preparedFields.phone?.isEmpty)?_c('VCol',{attrs:{"cols":"12","md":"6"}},[_c('VTextField',{staticClass:"required",attrs:{"outlined":"","label":_vm.$t('phones.one'),"type":"tel","rules":[
                    (v) => (v || '').trim().length > 0 || 'Заполните поле',
                    (v) =>
                      (v || '').trim().length <= 254 ||
                      'Это поле должно быть не больше 254 символов',
                  ]},model:{value:(_vm.preparedFields.phone.value),callback:function ($$v) {_vm.$set(_vm.preparedFields.phone, "value", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"preparedFields.phone.value"}})],1):_vm._e(),(_vm.preparedFields.email?.isEmpty)?_c('VCol',{attrs:{"cols":"12","md":"6"}},[_c('VTextField',{staticClass:"required",attrs:{"outlined":"","label":_vm.$t('emails.one'),"type":"email","rules":[
                    (v) => (v || '').trim().length > 0 || 'Заполните поле',
                    (v) =>
                      !(v || '').length ||
                      /.+@.+/.test(v) ||
                      'Невалидный email',
                    (v) =>
                      (v || '').trim().length <= 254 ||
                      'Это поле должно быть не больше 254 символов',
                  ]},model:{value:(_vm.preparedFields.email.value),callback:function ($$v) {_vm.$set(_vm.preparedFields.email, "value", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"preparedFields.email.value"}})],1):_vm._e(),(_vm.preparedFields.passport?.isEmpty)?_c('VCol',{attrs:{"cols":"12"}},[_c('VTextarea',{staticClass:"required",attrs:{"outlined":"","label":_vm.$t('user_profile_passport_info.one'),"placeholder":_vm.$t('user_profile_passport_info_placeholder.one'),"rows":"3","rules":[
                    (v) => (v || '').trim().length > 0 || 'Заполните поле',
                    (v) =>
                      (v || '').trim().length <= 254 ||
                      'Это поле должно быть не больше 254 символов',
                  ]},model:{value:(_vm.preparedFields.passport.value),callback:function ($$v) {_vm.$set(_vm.preparedFields.passport, "value", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"preparedFields.passport.value"}})],1):_vm._e(),(_vm.preparedFields.locality?.isEmpty)?_c('VCol',{attrs:{"cols":"12"}},[_c('VTextField',{staticClass:"required",attrs:{"outlined":"","label":_vm.$t('locality_of_a_person.one'),"rules":[
                    (v) => (v || '').trim().length > 0 || 'Заполните поле',
                    (v) =>
                      (v || '').trim().length <= 1023 ||
                      'Это поле должно быть не больше 1023 символов',
                  ]},model:{value:(_vm.preparedFields.locality.value),callback:function ($$v) {_vm.$set(_vm.preparedFields.locality, "value", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"preparedFields.locality.value"}})],1):_vm._e(),(_vm.preparedFields.address?.isEmpty)?_c('VCol',{attrs:{"cols":"12"}},[_c('VTextarea',{staticClass:"required",attrs:{"outlined":"","label":_vm.$t('address_of_a_person.one'),"rows":"3","rules":[
                    (v) => (v || '').trim().length > 0 || 'Заполните поле',
                    (v) =>
                      (v || '').trim().length <= 1023 ||
                      'Это поле должно быть не больше 1023 символов',
                  ]},model:{value:(_vm.preparedFields.address.value),callback:function ($$v) {_vm.$set(_vm.preparedFields.address, "value", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"preparedFields.address.value"}})],1):_vm._e(),(_vm.preparedFields.employer?.isEmpty)?_c('VCol',{attrs:{"cols":"12","md":"6"}},[_c('VTextField',{staticClass:"required",attrs:{"outlined":"","label":_vm.$t('employers.one'),"rules":[
                    (v) => (v || '').trim().length > 0 || 'Заполните поле',
                    (v) =>
                      (v || '').trim().length <= 254 ||
                      'Это поле должно быть не больше 254 символов',
                  ]},model:{value:(_vm.preparedFields.employer.value),callback:function ($$v) {_vm.$set(_vm.preparedFields.employer, "value", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"preparedFields.employer.value"}})],1):_vm._e(),(_vm.preparedFields.position?.isEmpty)?_c('VCol',{attrs:{"cols":"12","md":"6"}},[_c('VTextField',{staticClass:"required",attrs:{"outlined":"","label":_vm.$t('user_profile_position.one'),"rules":[
                    (v) => (v || '').trim().length > 0 || 'Заполните поле',
                    (v) =>
                      (v || '').trim().length <= 254 ||
                      'Это поле должно быть не больше 254 символов',
                  ]},model:{value:(_vm.preparedFields.position.value),callback:function ($$v) {_vm.$set(_vm.preparedFields.position, "value", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"preparedFields.position.value"}})],1):_vm._e(),(_vm.preparedFields.education_level?.isEmpty)?_c('VCol',{attrs:{"cols":"12"}},[_c('VCombobox',{attrs:{"items":_vm.educationOptions,"label":_vm.$t('education_level.one'),"outlined":"","hide-selected":"","rules":[
                    (v) => (v || '').trim().length > 0 || 'Заполните поле',
                    (v) =>
                      (v || '').trim().length <= 254 ||
                      'Это поле должно быть не больше 254 символов',
                  ]},model:{value:(_vm.preparedFields.education_level.value),callback:function ($$v) {_vm.$set(_vm.preparedFields.education_level, "value", $$v)},expression:"preparedFields.education_level.value"}})],1):_vm._e(),(_vm.preparedFields.education?.isEmpty)?_c('VCol',{attrs:{"cols":"12"}},[_c('VTextarea',{staticClass:"required",attrs:{"outlined":"","label":_vm.$t('information_education.one'),"rows":"3","rules":[
                    (v) => (v || '').trim().length > 0 || 'Заполните поле',
                    (v) =>
                      (v || '').trim().length <= 1023 ||
                      'Это поле должно быть не больше 1023 символов',
                  ]},model:{value:(_vm.preparedFields.education.value),callback:function ($$v) {_vm.$set(_vm.preparedFields.education, "value", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"preparedFields.education.value"}})],1):_vm._e(),(_vm.preparedFields.previous_certificate?.isEmpty)?_c('VCol',{attrs:{"cols":"12"}},[_c('VCard',{attrs:{"color":"#f5f5f5"}},[_c('VCardTitle',[_c('span',{staticClass:"required"},[_vm._v(_vm._s(_vm.$t("information_doc_education_of_the_user.one"))+":")])]),_c('VCardText',_vm._l((_vm.preparedFields.previous_certificate.value),function(certificate,idx){return _c('VCard',{key:idx,class:{'mb-4': idx !== _vm.preparedFields.previous_certificate.value.length, }},[_c('VListItem',[_c('VListItemContent'),_c('VListItemAction',[(_vm.preparedFields.previous_certificate.value?.length > 1)?_c('VBtn',{attrs:{"icon":"","color":"error","title":_vm.$t('delete.one')},on:{"click":function($event){return _vm.deleteCertificate(idx)}}},[_c('VIcon',[_vm._v("close")])],1):_vm._e()],1)],1),_c('VDivider'),_c('VCardText',[_c('VRow',[_c('VCol',{attrs:{"cols":"12","md":"6"}},[_c('VSelect',{staticClass:"required",attrs:{"dense":"","hide-details":"","items":_vm.certificateLevels,"clearable":"","label":_vm.$t('education_level.one'),"outlined":"","rules":[
                                (v) =>
                                  (v || '').trim().length > 0 ||
                                  'Заполните поле',
                              ]},model:{value:(certificate.level),callback:function ($$v) {_vm.$set(certificate, "level", $$v)},expression:"certificate.level"}})],1),_c('VCol',{attrs:{"cols":"12","md":"6"}},[_c('VTextField',{staticClass:"required",attrs:{"dense":"","hide-details":"","outlined":"","label":_vm.$t('certificate_full_name.one'),"rules":[
                                (v) =>
                                  (v || '').trim().length > 0 ||
                                  'Заполните поле',
                              ]},model:{value:(certificate.fullname),callback:function ($$v) {_vm.$set(certificate, "fullname", $$v)},expression:"certificate.fullname"}})],1),_c('VCol',{attrs:{"cols":"12","md":"4"}},[_c('VTextField',{staticClass:"required",attrs:{"dense":"","hide-details":"","outlined":"","label":_vm.$t('series.one'),"rules":[
                                (v) =>
                                  (v || '').trim().length > 0 ||
                                  'Заполните поле',
                              ]},model:{value:(certificate.series),callback:function ($$v) {_vm.$set(certificate, "series", $$v)},expression:"certificate.series"}})],1),_c('VCol',{attrs:{"cols":"12","md":"4"}},[_c('VTextField',{staticClass:"required",attrs:{"dense":"","hide-details":"","outlined":"","label":_vm.$t('numbers.one'),"rules":[
                                (v) =>
                                  (v || '').trim().length > 0 ||
                                  'Заполните поле',
                              ]},model:{value:(certificate.number),callback:function ($$v) {_vm.$set(certificate, "number", $$v)},expression:"certificate.number"}})],1),_c('VCol',{attrs:{"cols":"12","md":"4"}},[_c('VTextField',{staticClass:"required",attrs:{"dense":"","hide-details":"","outlined":"","type":"date","min":_vm.MIN_DATE,"max":_vm.MAX_DATE,"label":_vm.$t('signed_at.one'),"rules":[
                                (v) =>
                                  (v || '').trim().length > 0 ||
                                  'Заполните поле',
                              ]},model:{value:(certificate.signed_at),callback:function ($$v) {_vm.$set(certificate, "signed_at", $$v)},expression:"certificate.signed_at"}})],1),_c('VCol',{attrs:{"cols":"12","md":"6"}},[_c('VTextField',{staticClass:"required",attrs:{"dense":"","hide-details":"","outlined":"","label":_vm.$t('educational_institution.one'),"rules":[
                                (v) =>
                                  (v || '').trim().length > 0 ||
                                  'Заполните поле',
                              ]},model:{value:(certificate.edu),callback:function ($$v) {_vm.$set(certificate, "edu", $$v)},expression:"certificate.edu"}})],1),_c('VCol',{attrs:{"cols":"12","md":"6"}},[_c('VTextField',{staticClass:"required",attrs:{"dense":"","hide-details":"","outlined":"","label":_vm.$t('educational_institution_level.one'),"rules":[
                                (v) =>
                                  (v || '').trim().length > 0 ||
                                  'Заполните поле',
                              ]},model:{value:(certificate.edu_level),callback:function ($$v) {_vm.$set(certificate, "edu_level", $$v)},expression:"certificate.edu_level"}})],1)],1)],1)],1)}),1),_c('VCardActions',[_c('VBtn',{attrs:{"color":"#fff","depressed":""},on:{"click":_vm.addCertificate}},[_vm._v(" "+_vm._s(_vm.$t("add.one"))+" "+_vm._s(_vm.$t("documents.one"))+" ")])],1)],1)],1):_vm._e()],1)],1),_c('VCardActions',[_c('VBtn',{attrs:{"depressed":"","type":"submit","loading":_vm.isSubmitting,"large":_vm.$vuetify.breakpoint.lgAndUp,"color":"primary"},on:{"click":function($event){return _vm.save('work-form')}}},[_c('VIcon',{attrs:{"left":""}},[_vm._v(" save ")]),_vm._v(" "+_vm._s(_vm.$t("save.one"))+" ")],1)],1)],1)],1)],1)])],1):_c('UPlaceholder')
}
var staticRenderFns = []

export { render, staticRenderFns }